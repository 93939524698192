import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  type GridProps,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { Layout, PimoReactLayout } from "@pimo/pimo-app-builder";
import { useParams } from "react-router-dom";

import LayoutSideBar from "../pimo-components/layout-sidebar.tsx/layout-sidebar";
import { SideMenuEntryInterface } from "../types";

export type TwoColumnGridLayoutProps = {
  viewname?: string;
} & GridProps;

const Stage = styled(Box, {
  shouldForwardProp: (prop) => prop !== "enablePageBreak",
})<{ enablePageBreak: boolean }>(({ theme, enablePageBreak }) => ({
  flexGrow: 1,
  ...theme.mixins.toolbar,
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(1.5),
  "@media print": {
    backgroundColor: "unset",
    pageBreakBefore: enablePageBreak ? "always" : "avoid",
  },
}));

const buildTwoColumnGridLayoutReactComponent: (
  spacing: number,
  enablePageBreak: boolean,
  workspaceRoute: string,
  entries: SideMenuEntryInterface[],
  title?: string
) => PimoReactLayout<TwoColumnGridLayoutProps> =
  (spacing, enablePageBreak, workspaceRoute, entries, title) =>
  ({ components }) => {
    const theme = useTheme();
    const params = useParams();

    const TwoColumnGridLayoutContentComponents = components.filter(
      (component) => component.layoutProps?.viewname === params.viewname
    );

    return (
      <Grid
        container
        sx={{
          minHeight: "100vh",
        }}
        style={{
          margin: "-24px",
          paddingRight: "0",
          width: "calc(100% + 48px)",
          height: "calc(100% + 48px)",
        }}
      >
        <Grid
          item
          xs={3}
          sx={{
            backgroundColor: theme.palette.secondary.main,
            height: "100vh",
            padding: theme.spacing(2),
          }}
        >
          <Button
            onClick={() => {
              window.location.href = workspaceRoute;
            }}
            sx={{
              color: theme.palette.primary.main,
              width: "100%",
              marginBottom: theme.spacing(4),
            }}
          >
            <ArrowBack sx={{ mr: 1 }} />
            Back to Workspace
          </Button>
          <LayoutSideBar entries={entries} />
        </Grid>
        <Grid
          item
          xs={9}
          sx={{
            backgroundColor: theme.palette.secondary.main,
            padding: theme.spacing(4),
          }}
        >
          {title && (
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: theme.spacing(2) }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: theme.spacing(2),
                }}
              >
                {title}
              </Typography>
            </Grid>
          )}

          <Stage
            enablePageBreak={enablePageBreak}
            sx={{
              padding: theme.spacing(spacing),
              mb: theme.spacing(spacing),
            }}
          >
            <Grid container spacing={spacing}>
              {TwoColumnGridLayoutContentComponents.map((component, index) => {
                const Component = component?.render?.();
                return (
                  <Grid
                    item
                    key={`grid_item_${index}`}
                    {...component.layoutProps}
                  >
                    <Component />
                  </Grid>
                );
              })}
            </Grid>
          </Stage>
        </Grid>
      </Grid>
    );
  };

export class TwoColumnGridLayout implements Layout<TwoColumnGridLayoutProps> {
  private spacing: number;
  private enablePageBreak: boolean;
  private workspaceRoute: string;
  private entries: SideMenuEntryInterface[];
  private title: string;

  constructor({
    spacing = 1,
    enablePageBreak = false,
    workspaceRoute,
    entries,
    title,
  }: {
    spacing?: number;
    enablePageBreak?: boolean;
    workspaceRoute: string;
    entries: SideMenuEntryInterface[];
    title?: string;
  }) {
    this.spacing = spacing;
    this.enablePageBreak = enablePageBreak;
    this.workspaceRoute = workspaceRoute;
    this.entries = entries;
    this.title = title || "";
  }

  getLayoutComponent() {
    return buildTwoColumnGridLayoutReactComponent(
      this.spacing,
      this.enablePageBreak,
      this.workspaceRoute,
      this.entries,
      this.title
    );
  }
}

export default TwoColumnGridLayout;
