import {
  Add,
  AssignmentOutlined,
  FileDownloadOutlined,
  InsertChartOutlinedTwoTone,
  Logout,
  SettingsOutlined,
  Speed,
} from "@mui/icons-material";
import { App, EventEmitter, Plugin } from "@pimo/pimo-app-builder";
import {
  DefaultOverlayNotificationPayload,
  HeaderProps,
} from "@pimo/pimo-components";
import { generatePath } from "react-router-dom";

import { TracyAppState } from "../app";
import { TracyOverlay } from "../components/tracy-overlay";
import { APP_ROUTES } from "../constants";
import { STRAPI_URL } from "../env";
import { createOe } from "../helpers/fetch/create-oe";
import { NotificationsPlugin } from "@pimo/notifications-plugin";

export class TracyOverlayPlugin
  extends EventEmitter<"logout" | "overlay:settings-click">
  implements Plugin<TracyAppState>
{
  onRegister(app: App<TracyAppState>): void {
    const overlayView = app.createOverlayView({ name: "Overlay" });

    const overlay = overlayView.addComponent({
      component: TracyOverlay,
    });

    overlay.mapState(({ isSidebarOpen, userProfile, year, oes, banner }) => ({
      hasSearchFunctionality: true,
      notifications: { banner },
      header: {
        logo: { big: "allianz.svg", small: "allianz-small.svg" },
        username: `${userProfile?.name ?? "Name n/a"} (${
          userProfile?.email ?? "Email n/a"
        })`,
        entries: [
          userProfile?.isAdmin
            ? {
                icon: SettingsOutlined,
                onClick: () => this.fireEvent?.("overlay:settings-click"),
                text: "Settings",
              }
            : undefined,
          userProfile?.isAdmin
            ? {
                text: "Add OE",
                onClick: async () => {
                  const oeName = prompt("Enter OE name");
                  if (!oeName) {
                    return;
                  }
                  await createOe(oeName);
                  window.location.reload();
                },
                icon: Add,
              }
            : undefined,
          {
            text: "Download Excel Report (Current)",
            icon: FileDownloadOutlined,
            onClick: () =>
              window.open(`${STRAPI_URL}/api/bff/export/${year}/current`),
          },
          {
            text: "Download Excel Report (Residual)",
            icon: FileDownloadOutlined,
            onClick: () =>
              window.open(`${STRAPI_URL}/api/bff/export/${year}/residual`),
          },
          {
            text: "Logout",
            onClick: () => {
              this.fireEvent("logout");
            },
            icon: Logout,
          },
        ].filter(Boolean) as HeaderProps["entries"],
      },
      isSidebarOpen,
      menuEntries: [
        {
          title: "Dashboard",
          link: APP_ROUTES.groupDashboard,
          level: 0,
          icon: Speed,
        },
        {
          title: "Status Overview",
          link: APP_ROUTES.oeOverview,
          level: 0,
          icon: AssignmentOutlined,
        },
        {
          title: "OE Assessment",
          level: 0,
          icon: InsertChartOutlinedTwoTone,
          items: [
            ...oes.map((oe) => ({
              active: window.location.pathname.includes(
                `/oe-reports/${oe.id ?? 0}`
              ),
              level: 1,
              link: generatePath(APP_ROUTES.oeReport, {
                oeId: oe.id?.toString() ?? "",
              }),
              title: oe.name,
            })),
          ].sort((entry1, entry2) => entry1.title.localeCompare(entry2.title)),
        },
      ],
      searchFunction: (items, query) => {
        if (!items?.length) {
          return [];
        }

        const lowerCaseQuery = query?.toLowerCase();

        return oes
          .filter(
            ({ name, oeClusters }) =>
              name?.toLocaleLowerCase()?.includes(lowerCaseQuery) ||
              oeClusters?.some(({ name }) =>
                name?.toLocaleLowerCase()?.includes(lowerCaseQuery)
              )
          )
          .map(({ name, id }) => ({
            active: window.location.pathname.includes(`/oe-reports/${id ?? 0}`),
            level: 1,
            link: generatePath(APP_ROUTES.oeReport, {
              oeId: id?.toString() ?? "",
            }),
            title: name,
          }));
      },
      year,
    }));

    overlay.on("year-selected", ({ payload }) => {
      const yearSelectedPayload = payload as { year?: number };
      if (yearSelectedPayload?.year == null) {
        return;
      }

      app.patchAppState({
        year: yearSelectedPayload?.year,
      });
    });

    overlay.on("overlay:toggle-sidebar", () => {
      app.patchAppState({
        isSidebarOpen: !app.getAppState().isSidebarOpen,
      });
    });

    overlay.on("overlay:click-logo", () => {
      app.navigate(`/`);
    });

    overlay.on("overlay:notification-read", (params) => {
      const notificationsPlugin = app.getPluginByName<
        NotificationsPlugin<TracyAppState>
      >("NotificationsPlugin");

      void notificationsPlugin?.handleOverlayNotificationReadEvent(
        params as { payload: DefaultOverlayNotificationPayload }
      );
    });
  }
}
