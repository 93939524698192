import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { TitleCard } from "@pimo/pimo-components";

export type HomePageTitleCardProps = {
  title: string;
};

export const SettingsOverviewTitleCard: PimoReactComponent<
  HomePageTitleCardProps
> = ({ title }) => {
  return <TitleCard title={title} />;
};
